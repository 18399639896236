<template>
  <b-modal
    id="add-user-popup"
    modal-class="modal-add"
    :static="true"
    title-class="modal-add-title"
    hide-header-close
    title="Запросити користувача"
  >
    <form slot="default" action="">
      <div class="form-group">
        <div class="modal-add-form-container">
          <div class="modal-add-form-section">
            <icon name="person_icon5" class="modal-add-icon" />
          </div>
          <div class="modal-add-form-section">
            <div class="form-group">
              <div class="form-required-container">
                <icon name="required_icon" class="form-required-icon" />
                <input
                  type="text"
                  name="states[]"
                  v-model="user.phone"
                  class="form-input required"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="modal-add-description">
        Запрошувати до спільноти можна лише ріелторів) (За кожного
         запрошеного колегу отримай ще 7 днів безкоштовного доступу "Бонус
         нараховується коли запрошений колега набуде доступу до бази*")
      </p>
      <div class="form-group">
        <div class="modal-add-form-container">
          <div class="modal-add-form-section">
            <icon name="email_icon" class="modal-add-icon" />
          </div>
          <div class="modal-add-form-section">
            <div class="form-group">
              <div class="form-required-container">
                <input
                  type="email"
                  name="states[]"
                  v-model="user.email"
                  placeholder="Email"
                  class="form-input required"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="modal-add-form-container">
          <div class="modal-add-form-section">
            <icon name="person_icon6" class="modal-add-icon" />
          </div>
          <div class="modal-add-form-section">
            <div class="form-group">
              <div class="">
                <textarea
                  name="states[]"
                  v-model="user.comment"
                  placeholder="Коментар"
                  class="form-textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div slot="modal-footer">
      <button
        type="button"
        class="btn btn-filter-transparent"
        @click="$bvModal.hide('add-user-popup')"
      >
        Скасувати
      </button>
      <button type="button" class="btn btn-filter" style="width: 100px">
        Надіслати
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'AddUserPopup',
  data: () => {
    return {
      user: {
        phone: '+380',
        email: '',
        comment: ''
      }
    };
  }
};
</script>

<style scoped></style>
